<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        custom-class="common-edit"
        title="发货详情"
        width="600px"
    >
        <el-table :data="dataList" border stripe>
            <el-table-column label="设备号" prop="equipment" />
            <el-table-column label="生产序列号" prop="production" />
            <el-table-column label="资产序列号" prop="assets" />
        </el-table>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import { GET_GOODS_SEND_POLE_DETAIL } from "@/api/goods";

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    id: { type: [String, Number] }
});

let emit = defineEmits(["update:show"]);

watch(
    () => props.show,
    (newVal) => {
        if (newVal) {
            getDetail();
        } else {
            dataList.value = [];
        }
    },
    { deep: true }
);

// 获取详情
let dataList = ref([]);

let getDetail = () => {
    GET_GOODS_SEND_POLE_DETAIL({ id: props.id }).then((res) => {
        dataList.value = res.data.data;
        console.log(111, res.data.data);
    });
};

// 关闭
let onClose = () => {
    emit("update:show", false);
};
</script>
