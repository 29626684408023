<template>
    <!--    <gk-searchbar-->
    <!--        v-model:form="searchForm"-->
    <!--        :config="searchConfig"-->
    <!--        :fold-display-number="3"-->
    <!--        :foldable="true"-->
    <!--        :options="searchOptions"-->
    <!--        @reset="getDataList"-->
    <!--        @search="getDataList"-->
    <!--    />-->
    <div class="common-list">
        <div class="button-wapper">
            <el-button type="primary" @click="openEditDialog()">新增</el-button>
            <el-button type="danger" @click="onDelete()">删除</el-button>
        </div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" width="100" />
                <el-table-column label="订单号" prop="order_sn" />
                <el-table-column label="发货时间" prop="delivery_at" />
                <el-table-column label="资产客户">
                    <template #default="scope">{{ scope.row.role_user ? scope.row.role_user.name : "暂无" }} </template>
                </el-table-column>
                <el-table-column label="产品信息">
                    <template #default="scope">
                        <span v-for="(item, index) in scope.row.information">
                            {{ `${Object.keys(item)[0]}:${Object.values(item)[0]}` }}
                            {{ index !== scope.row.information.length - 1 ? "、" : "" }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="发货数量" prop="number" width="100" />
                <el-table-column label="收货状态" width="100">
                    <template #default="scope">{{ scope.row.is_goods === 0 ? "未收货" : "已收货" }}</template>
                </el-table-column>
                <el-table-column label="安装状态" width="100">
                    <template #default="scope">{{ scope.row.is_install === 0 ? "未安装" : "已安装" }}</template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                    <template #default="scope">
                        <el-button plain size="mini" type="primary" @click="openDetailDialog(scope.row.id)">
                            详情
                        </el-button>
                        <!--                        <el-button disabled plain size="mini" type="danger" @click="onDelete(scope.row.id)">-->
                        <!--                            删除-->
                        <!--                        </el-button>-->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
    <goods-send-edit
        :id="editDialog.id"
        v-model:show="editDialog.show"
        :detail="editDialog.detail"
        @update="getDataList"
    />
    <goods-send-detail v-model:show="detailDialog.show" :id="detailDialog.id" />
</template>

<script setup>
import { onMounted, ref } from "vue";
import useCommonList from "../../../../hooks/useCommonList";
import GoodsSendEdit from "./GoodsSendEdit.vue";
import GoodsSendDetail from "./GoodsSendDetail.vue";
import { BATCH_DELETE_GOODS_SEND, GET_GOODS_SEND_LIST } from "@/api/goods";
import { ElMessageBox, ElMessage } from "element-plus";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
});

// 搜索项
let searchConfig = ref([{ prop: "name", type: "input", label: "角色名" }]);

let searchOptions = ref({});

let searchForm = ref({
    name: null
});

// 获取数据
let getDataList = () => {
    GET_GOODS_SEND_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 增删改
let editDialog = ref({
    show: false,
    id: null,
    detail: null
});

let openEditDialog = (item = null) => {
    editDialog.value = {
        show: true,
        id: item ? item.id : null,
        detail: item
    };
};

let onDelete = (id = null) => {
    if (id) {
    } else {
        ElMessageBox.confirm("确认删除选中内容？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning"
        }).then(() => {
            BATCH_DELETE_GOODS_SEND({ ids: selectionIds.value }).then((res) => {
                if (res.data.code === 200) {
                    getDataList();
                    ElMessage.success("删除成功");
                }
            });
        });
    }
};

// 查看详情
let detailDialog = ref({
    show: false,
    id: null
});

let openDetailDialog = (id) => {
    detailDialog.value = {
        show: true,
        id: id
    };
};
</script>
