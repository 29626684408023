<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        custom-class="common-edit"
        title="新增发货"
        width="600px"
    >
        <el-form ref="dataFormRef" :label-width="100" :model="dataForm" :rules="dataRules" label-position="left">
            <el-form-item label="资产客户：" prop="user_id">
                <el-select v-model="dataForm.user_id" filterable placeholder="请选择资产客户">
                    <el-option
                        v-for="item in userOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <!--            <el-form-item label="发货电杆：" prop="goods">-->
            <!--                <el-select v-model="dataForm.goods" multiple filterable placeholder="请选择发货电杆">-->
            <!--                    <el-option-->
            <!--                        v-for="item in poleOptions"-->
            <!--                        :key="item.id"-->
            <!--                        :label="item.equipment"-->
            <!--                        :value="item.id"-->
            <!--                    ></el-option>-->
            <!--                </el-select>-->
            <!--            </el-form-item>-->
            <el-form-item label="发货时间：" prop="time">
                <el-date-picker v-model="dataForm.time" type="datetime" placeholder="请选择发货时间" />
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
                <el-input v-model="dataForm.remark" placeholder="请输入备注" />
            </el-form-item>
            <el-form-item label="产品信息：" prop="information">
                <div class="information-list">
                    <div class="information-container">
                        <template v-if="dataForm.information.length > 0">
                            <div v-for="(item, index) in dataForm.information" class="information-item">
                                <el-input v-model="item.name" placeholder="信息名称"></el-input>
                                <el-input v-model="item.value" placeholder="信息内容"></el-input>
                                <div class="information-buttons">
                                    <el-button type="primary" @click="addInformation">新增</el-button>
                                    <el-button :disabled="index === 0" type="danger" @click="deleteInformation(item)">
                                        删除
                                    </el-button>
                                </div>
                            </div>
                        </template>
                        <el-button v-else size="small" type="primary" @click="addInformation">新增信息</el-button>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" width="100" />
                <el-table-column label="设备号" prop="equipment" />
                <el-table-column label="生产序列号" prop="production" />
                <el-table-column label="资产序列号" prop="assets" />
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
        <template #footer>
            <el-button @click="onClose">取消</el-button>
            <el-button type="primary" @click="onSubmit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { nextTick, ref, watch } from "vue";
import { ElMessage } from "element-plus";
import { ADD_GOODS_SEND, GET_GOODS_SEND_POLES, GET_GOODS_SEND_USERS } from "@/api/goods";
import useCommonList from "@/hooks/useCommonList";

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    id: { type: [String, Number] },
    detail: { type: Object }
});

let emit = defineEmits(["update:show", "update"]);

watch(
    () => ({ id: props.id, show: props.show }),
    (newVal) => {
        if (newVal.show) getOptions();
        if (newVal.show && newVal.id) getDetail();
    },
    { deep: true }
);

// 获取选项
let userOptions = ref([]);

let getOptions = () => {
    GET_GOODS_SEND_USERS({}).then((res) => {
        userOptions.value = res.data.data;
        console.log(res.data.data);
    });
    getDataList();
};

// 获取详情
let getDetail = async () => {
    await nextTick();
    // dataForm.value = {};
};

// 表单数据
let dataForm = ref({
    user_id: null,
    goods: [],
    time: null,
    remark: null,
    information: []
});

let dataFormRef = ref();

let dataRules = {
    user_id: [{ required: true, message: "请选择资产客户！", trigger: "change" }],
    // goods: [{ required: true, message: "请选择发货电杆！", trigger: "change" }],
    time: [{ required: true, message: "请选择发货时间！", trigger: "change" }],
    remark: [{ required: true, message: "请输入备注！", trigger: "blur" }],
    information: [{ required: true, message: "请输入产品信息！", trigger: "change" }]
};

// 获取数据
let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

let getDataList = () => {
    GET_GOODS_SEND_POLES({
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
    });
};

// 信息处理
let addInformation = () => {
    dataForm.value.information.push({ name: "", value: "" });
};

let deleteInformation = (val) => {
    dataForm.value.information = dataForm.value.information.filter((item) => {
        return item !== val;
    });
};

// 关闭
let onClose = () => {
    if (dataFormRef.value) dataFormRef.value.resetFields();
    emit("update");
    emit("update:show", false);
};

// 提交
let onSubmit = () => {
    let infoArr = dataForm.value.information.map((item) => {
        let obj = {};
        obj[item.name] = item.value;

        return obj;
    });
    dataFormRef.value.validate((valid) => {
        if (valid) {
            if (!props.id) {
                if (selectionIds.value.length < 1) {
                    ElMessage.error("请选择发货电杆！");
                    return false;
                }
                ADD_GOODS_SEND({
                    ...dataForm.value,
                    goods: selectionIds.value,
                    information: infoArr
                }).then((res) => {
                    if (res.data.code === 200) {
                        onClose();
                        ElMessage.success("新增成功");
                    }
                });
            }
        }
    });
};
</script>

<style lang="scss" scoped>
.information-list {
    display: flex;
    align-items: center;

    .information-container {
        .information-item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .el-input {
                margin-right: 10px;
            }

            .information-buttons {
                display: flex;
                align-items: center;
            }
        }
    }
}
.pagination-wrapper {
    margin-top: 20px;
}
</style>
